import { PictureControls } from "./PictureControls"
import { useDispatch, useSelector } from "react-redux"
import { getActiveTab, getAuthError, getRequest, getScreenshot, registerUser, setAuthError, setRegisterEmail, setRegisterName, setRegisterPassword, setRegisterRepeatPassword } from "../features/auth/authSlice"
import { Navigate } from "react-router-dom";
import { useState } from "react";
import { getFaces } from "../features/auth/facenetSlice";
import axios from "axios";

export const Register = ({enableInput}) => {

    const isFirefox = typeof InstallTrigger !== 'undefined';

    const activeTab = useSelector(getActiveTab)
    const dispatch = useDispatch()
    const request = useSelector(getRequest)
    const name = request.register.name
    // const email = request.register.email
    // const password = request.register.password
    // const repeatPassword = request.register.repeatPassword
    const screenshot = useSelector(getScreenshot)
    const error = useSelector(getAuthError)
    const faces = useSelector(getFaces)
    const [registerState, setRegisterState] = useState(false);

    const manageForm = async(e) => {
        e.preventDefault()

        if(
            name != null &&

            screenshot != null &&
                        faces.length !== 0
        ){
            const user = {name, screenshot, descriptor: Object.values(faces[0].descriptor)};
            const url = `${process.env.REACT_APP_BACKEND_URL}api/auth/register`;
          const response = await axios.post(url, user);
            console.log(response);
            if (response.status === 200){
                setRegisterState(true);
            }

        }
    }


    return(
        <form className={'login100-form ' + (activeTab === 'register' ? 'active' : '')}>
            {registerState && <>    
            <div>
                <h2>Hi {name}!</h2>
                <h3>Welcome to the Face Recognition App!</h3>
            </div>
            </>}
            <div className="my-wrap-input wrap-input50 wrap-input50-m" onClick={() => enableInput('name')}>
                <input
                    type="text"
                    name="name"
                    id="name"
                    className="my-input input50"
                    placeholder="Name"
                    disabled={isFirefox ? false : true}
                    value={name || ''}
                    onChange={(e) => dispatch(setRegisterName(e.target.value))}
                />
            </div>

            {/* <div className="my-wrap-input wrap-input50" onClick={() => enableInput('register-email')}>
                <input
                    type="email"
                    name="register-email"
                    id="register-email"
                    className="my-input input50"
                    placeholder="Email"
                    disabled={isFirefox ? false : true}
                    value={email || ''}
                    onChange={(e) => dispatch(setRegisterEmail(e.target.value))}
                />
            </div>

            <div className="my-wrap-input wrap-input50 wrap-input50-m" onClick={() => enableInput('register-password')}>
                <input
                    type="password"
                    name="register-password"
                    id="register-password"
                    className="my-input input50"
                    placeholder="Password"
                    disabled={isFirefox ? false : true}
                    value={password || ''}
                    onKeyUp={validatePassword}
                    onChange={(e) => dispatch(setRegisterPassword(e.target.value))}
                />
            </div>

            <div className="my-wrap-input wrap-input50" onClick={() => enableInput('repeat-password')}>
                <input
                    type="password"
                    name="repeat-password"
                    id="repeat-password"
                    className="my-input input50"
                    placeholder="Repeat Password"
                    disabled={isFirefox ? false : true}
                    value={repeatPassword || ''}
                    onChange={(e) => dispatch(setRegisterRepeatPassword(e.target.value))}
                />
            </div> */}

            <PictureControls />

            <div className="container-login100-form-btn">
                <button
                    type="button"
                    className="my-submit-btn zoom-in"
                    onClick={(e) => manageForm(e)}
                >Register</button>
            </div>

        </form>
    )
}