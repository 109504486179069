import { useDispatch, useSelector } from "react-redux"
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { getActiveTab, getAuthError, getRequest, getScreenshot, loginUser, setAuthError, setLoginEmail, setLoginPassword, setUser } from "../features/auth/authSlice"
import { getFaces } from "../features/auth/facenetSlice";
import { PictureControls } from "./PictureControls"
import axios from "axios";

export const Login = ({enableInput}) => {

    const isFirefox = typeof InstallTrigger !== 'undefined';

    const activeTab = useSelector(getActiveTab)
    const dispatch = useDispatch()
    const request = useSelector(getRequest)
    // const email = request.login.email
    // const password = request.login.password
    const screenshot = useSelector(getScreenshot)
    const error = useSelector(getAuthError)
    const faces = useSelector(getFaces)
    const [loginState, setLoginState] = useState(false);
    const [userName, setUserName] = useState('');

    const manageForm = async(e) => {
        e.preventDefault()
        if(
            screenshot != null &&
            faces.length !== 0
        ){
            const user = {screenshot, descriptor: Object.values(faces[0].descriptor)}
            const url = `${process.env.REACT_APP_BACKEND_URL}api/auth/login`;

            const response = await axios.post(url, user);
            console.log(response);
            if (response.status === 200){
                setLoginState(true);
                console.log("hii");
                setUserName(response.data.name);
            }
        }
    }

  

    return(
        <form className={'login100-form ' + (activeTab === 'login' ? 'active' : '')}>
            {loginState && <>
            <div>
                <h2>Hi {userName}!</h2>
                <h3>Welcome to the Face Recognition App!</h3>
            </div>
            </>}

            <PictureControls />

            <div className="container-login100-form-btn">
                <button
                    type="button"
                    className="my-submit-btn zoom-in"
                    onClick={(e) => manageForm(e)}
                >Login</button>
            </div>

        </form>
    )
}